import React from 'react';

const Loader = () => (
  <>
    <svg
      width="128"
      height="128"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="custom-loader"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.982 31.903L15.9784 35.7712C13.7496 37.9247 11.9335 39.7591 11.9335 39.8787C11.9335 39.9984 15.6069 40.0383 20.0645 39.9585L28.1956 39.8389L19.982 31.903ZM19.9407 35.0933L17.1341 37.8848H19.8994C21.4266 37.8848 22.6648 37.8449 22.6648 37.7652C22.6648 37.6854 22.0457 37.0873 21.3028 36.3695L19.9407 35.0933Z"
        fill="#6297B5"
      />
      <path
        d="M19.982 15.9515L15.4418 20.3382C11.108 24.5254 10.3651 25.0439 9.74598 24.4457C8.96177 23.7279 9.8698 22.4916 14.7402 17.8258L19.982 12.7612L25.5127 18.105C30.3006 22.7309 31.0022 23.5683 30.5482 24.0868C30.218 24.4457 29.764 24.7248 29.5163 24.7248C29.2687 24.7248 26.9986 22.7309 24.5222 20.3382L19.982 15.9515Z"
        fill="#6297B5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8828 28.6728C13.3781 28.4335 15.1942 26.9182 16.8864 25.2831L19.982 22.3321L22.8712 25.1635C26.1731 28.3937 27.3701 29.1115 29.4751 29.1115C33.1485 29.1115 35.9139 25.9212 35.2535 22.4119C35.0058 21.1756 33.7263 19.74 27.4939 13.6385L19.982 6.3806L13.0479 13.0404C9.20941 16.7491 5.82493 20.2185 5.49473 20.8167C4.7518 22.2125 4.7518 24.8445 5.53601 26.3599C6.81551 28.6728 10.4889 29.8293 12.8828 28.6728ZM7.18698 22.7309C6.81551 24.326 7.64099 25.8813 9.08559 26.4795C9.78725 26.7586 10.7778 26.9182 11.2731 26.8384C11.8097 26.7188 13.956 24.9641 16.1022 22.8904L19.9407 19.1418L23.9443 22.9303C27.7003 26.5194 28.3607 26.9182 30.3006 26.7586C31.5388 26.639 32.9834 24.8843 32.9834 23.4487C32.9834 22.372 32.0753 21.3351 26.5033 15.8717L20.0233 9.5709L13.7083 15.6325C9.82853 19.3412 7.3108 22.0928 7.18698 22.7309Z"
        fill="#6297B5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9465 32.7006C15.9371 32.2619 17.4643 31.1852 18.331 30.3078L19.8994 28.7526L21.7155 30.3477C22.7061 31.2251 24.3158 32.3018 25.2651 32.7405C27.3288 33.6178 31.5801 33.7374 33.5612 32.9398C37.2346 31.4643 40 27.4366 40 23.5285C40 19.6203 38.9681 18.2645 28.9798 8.65369L19.9407 0L10.6127 9.05248C1.94515 17.5068 1.24349 18.3043 0.624372 20.2983C-0.201113 22.9702 -0.201113 24.0469 0.583098 26.5592C1.8626 30.7465 5.82493 33.4981 10.5715 33.4981C12.3463 33.4981 13.7496 33.219 14.9465 32.7006ZM8.01246 31.0257C3.38975 29.4704 1.16094 24.326 3.26592 20.1786C3.76121 19.1817 7.26953 15.4331 12.0573 10.847L20.0233 3.1903L28.5258 11.4452C36.4504 19.2216 37.0283 19.8596 37.441 21.694C38.349 25.8813 35.8313 30.0287 31.7039 31.1054C28.3607 31.9828 25.6366 31.0257 22.2521 27.7157L20.0233 25.5224L17.2992 28.0746C13.8321 31.3048 11.2731 32.1423 8.01246 31.0257Z"
        fill="#6297B5"
      />
    </svg>
    <svg
      width="264"
      height="25"
      viewBox="0 0 264 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginTop: '3rem' }}
    >
      <path
        d=" M19.5312 1.71875C19.9583 1.71875 20.1719 1.94271 20.1719 2.39062C20.1719 2.61979 20.099 2.88542 19.9531
      3.1875C19.8177 3.47917 19.7083 3.71875 19.625 3.90625L11.1406 24.8594L0.859375 1.89062H9.39062L14.3594
      13.5781L17.8438 4.85938C18.2188 3.92188 18.4635 3.24479 18.5781 2.82812C18.6927 2.41146 18.8177 2.125 18.9531
      1.96875C19.0885 1.80208 19.2812 1.71875 19.5312 1.71875ZM23.1875 1.89062H31.1562V24H23.1875V1.89062ZM37.8281
      21.7656L37.875 23.3281C37.875 23.599 37.8021 23.8073 37.6562 23.9531C37.5104 24.099 37.3333 24.1719 37.125
      24.1719C36.9271 24.1719 36.7604 24.1042 36.625 23.9688C36.4896 23.8333 36.4219 23.6875 36.4219 23.5312C36.4219
      23.3646 36.4323 23.2135 36.4531 23.0781C36.4844 22.9323 36.5104 22.7708 36.5312 22.5938C36.5833 22.2917 36.6094
      21.5365 36.6094 20.3281V0.890625L53.5938 14.3594V5.04688C53.5938 4.33854 53.5729 3.83333 53.5312 3.53125C53.4792
      3.04167 53.4531 2.69792 53.4531 2.5C53.4531 1.97917 53.6771 1.71875 54.125 1.71875C54.3021 1.71875 54.4688 1.78125
      54.625 1.90625C54.7917 2.03125 54.875 2.21354 54.875 2.45312V3L54.8125 5.26562V24.8594L37.8281
      11.5312V21.7656ZM76.4688 2.29688C76.4688 2.77604 75.9635 3.01562 74.9531
      3.01562H71.3281V24H63.3281V3.01562H60.9844C60.1823 3.01562 59.6302 3.05208 59.3281 3.125C58.9323 3.20833 58.6458
      3.25 58.4688 3.25C57.9375 3.25 57.6719 3.04167 57.6719 2.625C57.6719 2.13542 58.1875 1.89062 59.2188
      1.89062H73.1562C73.875 1.89062 74.4062 1.85938 74.75 1.79688C75.1667 1.72396 75.4792 1.6875 75.6875 1.6875C76.2083
      1.6875 76.4688 1.89062 76.4688 2.29688ZM83.7031 21.2188C81.7969 21.2188 80.4948 21.2031 79.7969 21.1719L79.3438
      22.25C79.2083 22.5521 79.0833 22.8698 78.9688 23.2031C78.7292 23.849 78.3958 24.1719 77.9688 24.1719C77.7708
      24.1719 77.6094 24.1094 77.4844 23.9844C77.3594 23.8594 77.2969 23.7292 77.2969 23.5938C77.2969 23.3646 77.3958
      23.1094 77.5938 22.8281C77.9062 22.3906 78.2135 21.8177 78.5156 21.1094C77.6198 21.026 77.1719 20.7656 77.1719
      20.3281C77.1719 19.8906 77.4271 19.6719 77.9375 19.6719C78.25 19.6719 78.6146 19.724 79.0312 19.8281L86.8281
      0.890625L97.5156 24H89.375L88.0781 21.2188H83.7031ZM80.2969 19.9531C81.2969 20.0365 82.1927 20.0781 82.9844
      20.0781H87.5625L83.6562 11.6562L80.2969 19.9531ZM119.531 22.8594L119.562 21.7812V19.3125C118.094 21.7604 115.927
      23.3177 113.062 23.9844C112.156 24.1927 111.099 24.2969 109.891 24.2969C108.693 24.2969 107.448 24.0469 106.156
      23.5469C104.875 23.0469 103.75 22.3229 102.781 21.375C100.656 19.3125 99.5938 16.599 99.5938 13.2344C99.5938
      10.0156 100.719 7.29167 102.969 5.0625C105.312 2.76042 108.255 1.60938 111.797 1.60938C113.589 1.60938 115.208
      1.94271 116.656 2.60938C117.542 3.00521 118.167 3.375 118.531 3.71875C118.896 4.05208 119.078 4.32292 119.078
      4.53125C119.078 4.72917 119.016 4.90104 118.891 5.04688C118.766 5.19271 118.589 5.26562 118.359 5.26562C118.141
      5.26562 117.896 5.14583 117.625 4.90625C116.823 4.19792 116.115 3.73438 115.5 3.51562C114.896 3.29688 114.411
      3.14062 114.047 3.04688C113.682 2.95312 113.354 2.88542 113.062 2.84375C112.615 2.78125 112.083 2.75 111.469
      2.75C110.854 2.75 110.286 2.80208 109.766 2.90625V23.125C109.88 23.1354 109.995 23.1406 110.109
      23.1406H110.422C113.516 23.1406 116.021 21.9688 117.938 19.625C118.594 18.8229 119.135 17.875 119.562
      16.7812V15.2812C119.562 15.2812 119.536 14.8125 119.484 13.875C119.484 13.3229 119.703 13.0469 120.141
      13.0469C120.37 13.0469 120.547 13.125 120.672 13.2812C120.797 13.4271 120.859 13.6094 120.859 13.8281L120.766
      15.125V20.9375L120.859 23.3594C120.859 23.6094 120.786 23.8073 120.641 23.9531C120.505 24.099 120.344 24.1719
      120.156 24.1719C119.698 24.1719 119.469 23.9323 119.469 23.4531C119.469 23.3281 119.49 23.1302 119.531
      22.8594ZM140 22.6562C140.531 22.6562 140.797 22.8646 140.797 23.2812C140.797 23.4688 140.682 23.6354 140.453
      23.7812C140.224 23.9271 139.828 24 139.266 24H126.031V1.89062C130.135 1.89062 134.24 1.89062 138.344
      1.89062L139.625 1.84375C140.406 1.84375 140.797 2.07292 140.797 2.53125C140.797 2.70833 140.729 2.875 140.594
      3.03125C140.469 3.17708 140.323 3.25 140.156 3.25C139.885 3.25 139.599 3.21875 139.297 3.15625C138.849 3.0625
      138.234 3.01562 137.453 3.01562H134V7.78125H136.844C136.844 7.78125 137.484 7.75521 138.766 7.70312C138.995
      7.70312 139.188 7.76042 139.344 7.875C139.51 7.97917 139.594 8.13021 139.594 8.32812C139.594 8.76562 139.302
      8.98438 138.719 8.98438L138.047 8.96875C137.703 8.9375 137.417 8.92188 137.188 8.92188H134V22.8594H137.453C138.224
      22.8594 138.771 22.8281 139.094 22.7656C139.479 22.6927 139.781 22.6562 140 22.6562ZM162.453 17.5156L163.844
      17.5781C165.865 17.5781 167.531 16.9115 168.844 15.5781C170.177 14.2344 170.875 12.4948 170.938 10.3594C171.052
      7.06771 169.469 4.84375 166.188 3.6875C164.958 3.25 163.359 3.03125 161.391
      3.03125H160.078V24H152.109V1.89062H158.094C161.323 1.89062 163.271 1.9375 163.938 2.03125C164.604 2.125 165.297
      2.24479 166.016 2.39062C166.745 2.53646 167.526 2.85417 168.359 3.34375C169.203 3.83333 169.917 4.41667 170.5
      5.09375C171.677 6.46875 172.245 8.08333 172.203 9.9375C172.141 12.3021 171.448 14.2708 170.125 15.8438C168.781
      17.4271 166.927 18.4062 164.562 18.7812C163.875 18.875 163.333 18.9219 162.938 18.9219C162 18.9219 161.531 18.6667
      161.531 18.1562C161.531 17.9688 161.615 17.8177 161.781 17.7031C161.948 17.5781 162.172 17.5156 162.453
      17.5156ZM178.141 21.1875C177.109 20.1771 176.286 18.9583 175.672 17.5312C175.068 16.0938 174.766 14.5312 174.766
      12.8438C174.766 11.1458 175.052 9.625 175.625 8.28125C176.208 6.92708 177.01 5.75521 178.031 4.76562C180.219
      2.66146 182.964 1.60938 186.266 1.60938C189.547 1.60938 192.234 2.64583 194.328 4.71875C196.453 6.82292 197.516
      9.55208 197.516 12.9062C197.516 16.25 196.427 19 194.25 21.1562C192.885 22.4896 191.208 23.4115 189.219
      23.9219C188.229 24.1719 187.078 24.2969 185.766 24.2969C184.464 24.2969 183.125 24.0312 181.75 23.5C180.375
      22.9583 179.172 22.1875 178.141 21.1875ZM186.25 2.75C185.677 2.75 185.141 2.79167 184.641 2.875V23.0312C185.12
      23.1146 185.656 23.1562 186.25 23.1562C189.031 23.1562 191.375 22.2292 193.281 20.375C195.271 18.4479 196.266
      15.9583 196.266 12.9062C196.266 10 195.333 7.58333 193.469 5.65625C191.583 3.71875 189.177 2.75 186.25
      2.75ZM201.984 1.89062H209.953V8.35938L215.516 4.15625C216.297 3.5625 216.865 3.07812 217.219 2.70312C217.573
      2.32812 217.792 2.10417 217.875 2.03125C217.969 1.94792 218.052 1.88542 218.125 1.84375C218.25 1.76042 218.406
      1.71875 218.594 1.71875C218.792 1.71875 218.948 1.77604 219.062 1.89062C219.177 1.99479 219.234 2.11458 219.234
      2.25C219.234 2.375 219.219 2.48438 219.188 2.57812C219.156 2.66146 219.094 2.76042 219 2.875C218.792 3.125 218.396
      3.47396 217.812 3.92188L214.312 6.57812L220.172 20.9062C220.286 21.1771 220.396 21.4167 220.5 21.625L220.75
      22.1875L221.031 22.7188C221.104 22.8646 221.167 23.0052 221.219 23.1406C221.271 23.276 221.292 23.4062 221.281
      23.5312C221.271 23.6562 221.234 23.7708 221.172 23.875C221.026 24.1042 220.849 24.2188 220.641 24.2188C220.349
      24.2188 220.13 24.1354 219.984 23.9688C219.797 23.7396 219.656 23.4844 219.562 23.2031L219.281 22.3281C219.188
      22.0365 219.062 21.6875 218.906 21.2812L213.234 7.39062L209.953 9.875V24H201.984V1.89062ZM238.406 22.6562C238.938
      22.6562 239.203 22.8646 239.203 23.2812C239.203 23.4688 239.089 23.6354 238.859 23.7812C238.63 23.9271 238.234 24
      237.672 24H224.438V1.89062C228.542 1.89062 232.646 1.89062 236.75 1.89062L238.031 1.84375C238.812 1.84375 239.203
      2.07292 239.203 2.53125C239.203 2.70833 239.135 2.875 239 3.03125C238.875 3.17708 238.729 3.25 238.562
      3.25C238.292 3.25 238.005 3.21875 237.703 3.15625C237.255 3.0625 236.641 3.01562 235.859
      3.01562H232.406V7.78125H235.25C235.25 7.78125 235.891 7.75521 237.172 7.70312C237.401 7.70312 237.594 7.76042
      237.75 7.875C237.917 7.97917 238 8.13021 238 8.32812C238 8.76562 237.708 8.98438 237.125 8.98438L236.453
      8.96875C236.109 8.9375 235.823 8.92188 235.594 8.92188H232.406V22.8594H235.859C236.63 22.8594 237.177 22.8281
      237.5 22.7656C237.885 22.6927 238.188 22.6562 238.406 22.6562ZM253.922 16.4219L255.203 16.5156C257.036 16.5156
      258.594 15.875 259.875 14.5938C261.156 13.3125 261.797 11.7188 261.797 9.8125C261.797 7.3125 260.698 5.45833 258.5
      4.25C257.292 3.58333 256 3.19792 254.625 3.09375C254.042 3.04167 253.5 3.01562 253 3.01562H251.938C251.719 3.01562
      251.526 3.02083 251.359 3.03125V24H243.391V1.89062C245.828 1.89062 247.802 1.89062 249.312 1.89062C250.823 1.89062
      251.807 1.89583 252.266 1.90625C252.724 1.91667 253.13 1.93229 253.484 1.95312C253.849 1.96354 254.188 1.97917
      254.5 2C254.958 2.04167 255.604 2.14062 256.438 2.29688C257.281 2.45312 258.115 2.75 258.938 3.1875C259.76 3.625
      260.469 4.16667 261.062 4.8125C262.344 6.1875 262.984 7.82292 262.984 9.71875C262.984 11.5938 262.391 13.25
      261.203 14.6875C259.984 16.1562 258.359 17.1042 256.328 17.5312C257.297 20.5312 258.531 22.3229 260.031
      22.9062C260.49 23.0833 260.948 23.1719 261.406 23.1719L262.641 22.875C263.026 22.875 263.219 23.0729 263.219
      23.4688C263.219 24.0208 262.573 24.2969 261.281 24.2969C260.344 24.2969 259.484 24.0365 258.703 23.5156C257.484
      22.7865 256.448 21.3698 255.594 19.2656L255.141 18.1562C255.068 17.9792 255 17.8698 254.938 17.8281C254.885
      17.7865 254.786 17.7656 254.641 17.7656C254.505 17.7656 254.385 17.776 254.281 17.7969L253.906 17.8125C253.49
      17.8125 253.203 17.7344 253.047 17.5781C252.901 17.4219 252.828 17.2604 252.828 17.0938C252.828 16.6458 253.193
      16.4219 253.922 16.4219Z"
        fill="#245069"
      />
    </svg>
  </>
);

export default Loader;
