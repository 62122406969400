import styled from 'styled-components';

const RelativeWrapper = styled.div`
  position: relative;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
`;

export default RelativeWrapper;
